.settings-panel {
	.expanded {
		&.theme-colors {
			display: block;
			box-shadow: none;
			border: 0;
			background: transparent;



			> ul {
				height: auto;

				> li {

					[data-action] {


						width: 36px;
						height: 36px;
						margin-right: 4px;
						margin-bottom: 4px;
						border-radius: 4px;

						&:hover {
							opacity: 1;
						}
					}

				}

				&:last-child {
					margin-right:0;
				}
			}	

			/*@include theme-button-color ($theme-1-fusion, $theme-1-primary, $theme-1-info, $theme-1-success, $theme-1-warning, $theme-1-danger)*/

			#myapp-0 { 
				background: #886ab5;
				box-shadow: inset 0 0 0 3px rgb(43, 161, 255);
			 }
			#myapp-1 { 
				background: #b56a9f;
			 }
			#myapp-2 { 
				background: #9fcb3d;
			} 
			#myapp-3 { 
				background: #4679cc;
			} 
			#myapp-4 { 
				background: #2198F3;
			}
			#myapp-5 { 
				background: #6ab5b4;
			}
			#myapp-6 { 
				background: #dd5293;
			}
			#myapp-7 { 
				background: #868e96;
			}
			#myapp-8 { 
				background: #7c91df;
			}
			#myapp-9 { 
				background: #e59c6c;
			}
			#myapp-10 { 
				background: #778c85;
			}
			#myapp-11 { 
				background: #a2b077;
			}
			#myapp-12 { 
				background: #7976b3;
			}
			#myapp-13 { 
				background: #55ce5f;
			}
			#myapp-14 { 
				background: #5c4581;
			}
			#myapp-15 { 
				background: #5c4581;
			}

		}
	}
}
							